<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    no-fade
    variant="transparent"
    :opacity="0.5"
  >
    <component
      :is="topupData === undefined ? 'div' : 'b-card'"
    >
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="topupData === undefined"
      >
        <h4 class="alert-heading">
          {{ $t('topup.errFetchData_1') }}
        </h4>
        <div class="alert-body">
          {{ $t('topup.errFetchData_2') }}
          <b-link
            class="alert-link"
            :to="{ name: 'apps-topup-list'}"
          >
            {{ $t('topup.list') }}
          </b-link>
          {{ $t('topup.errFetchData_3') }}
        </div>
      </b-alert>
      <topup-view-info
        v-if="topupData"
        :topup-data="topupData"
        @confirm-manual-success="fetchTopupData"
      />
    </component>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import {
  ref, onUnmounted,
} from '@vue/composition-api'

import {
  TOPUP_APP_STORE_MODULE_NAME as TOPUP_STORE,
} from '@/constants/topup'
import store from '@/store'
import router from '@/router'

import topupStoreModule from '../topupStoreModule'
import TopupViewInfo from './TopupViewInfo.vue'

export default {
  components: {
    BOverlay,
    BCard,
    BAlert,
    BLink,

    TopupViewInfo,
  },

  setup() {
    // Register module
    if (!store.hasModule(TOPUP_STORE)) store.registerModule(TOPUP_STORE, topupStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TOPUP_STORE)) store.unregisterModule(TOPUP_STORE)
    })

    const loading = ref(true)
    const topupData = ref(null)

    const fetchTopupData = () => store.dispatch('app-topup/getTopupById', { id: router.currentRoute.params.id })
      .then(response => {
        topupData.value = response.data
      })
      .catch(error => {
        if (error) {
          topupData.value = undefined
        }
      })
      .finally(() => {
        loading.value = false
      })

    fetchTopupData()

    return {
      loading,
      topupData,

      fetchTopupData,
    }
  },
}
</script>
