var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c(_vm.topupData === undefined ? 'div' : 'b-card', {
    tag: "component"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.topupData === undefined
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" " + _vm._s(_vm.$t('topup.errFetchData_1')) + " ")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" " + _vm._s(_vm.$t('topup.errFetchData_2')) + " "), _c('b-link', {
    staticClass: "alert-link",
    attrs: {
      "to": {
        name: 'apps-topup-list'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('topup.list')) + " ")]), _vm._v(" " + _vm._s(_vm.$t('topup.errFetchData_3')) + " ")], 1)]), _vm.topupData ? _c('topup-view-info', {
    attrs: {
      "topup-data": _vm.topupData
    },
    on: {
      "confirm-manual-success": _vm.fetchTopupData
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }